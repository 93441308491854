import { createRouter, createWebHistory } from "vue-router";
import Monatsabrechnung from "../views/Monatsabrechnung.vue";
import ChargingSessions from "../views/ChargingSessions/ChargingSessions.vue";
import MonatsabrechnungDetails from "../views/MonatsabrechnungDetails.vue";
import ChargingSessionsDetails from "../views/ChargingSessions/ChargingSessionsDetails.vue";
import NotFound from "../views/NotFound.vue";
const routes = [
  {
    path: "/",
    redirect: "/monatsabrechnung",
  },
  {
    path: "/monatsabrechnung",
    name: "Monatsabrechnung",
    component: Monatsabrechnung,
  },
  {
    path: "/chargingsessions",
    name: "ChargingSessions",
    component: ChargingSessions,
  },
  {
    path: "/chargingsessions/:id",
    name: "ChargingSessionsDetails",
    component: ChargingSessionsDetails,
    props: true,
  },
  {
    path: "/monatsabrechnung/:id",
    name: "MonatsabrechnungDetails",
    component: MonatsabrechnungDetails,
    props: true,
  },
  //catch all 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
