<template>
  <h1>Monatsabrechnung der E-Autos</h1>
  <div v-for="month in months" :key="month" class="job">
    <router-link
      :to="{
        name: 'MonatsabrechnungDetails',
        params: {
          id: month,
        },
      }"
    >
      <h2>{{ month }}</h2>
    </router-link>
  </div>
</template>
<script>
import urlJson from "../files/url.json";
export default {
  data() {
    return {
      months: [],
    };
  },
  methods: {
    formatDateToMonth(dateString) {
      dateString.forEach((el) => {
        const date = new Date(el.start_event_timestamp);
        let datum = new Intl.DateTimeFormat("default", {
          month: "long",
          year: "numeric",
        }).format(date);
        if (!this.months.includes(datum)) {
          this.months.push(datum);
        }
      });
    },
  },
  mounted() {
    fetch(urlJson, {
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((data) => this.formatDateToMonth(data.data))
      .catch((err) => console.log(err.message));
  },
};
</script>
