<template>
  <h1>Charging Session</h1>
  <h3>{{ rfidAlias }}</h3>
  <p>Transaktion: {{ id }}</p>
  <p>Start: {{ start_time }} Uhr</p>
  <p>Stop: {{ stop_time }} Uhr</p>
  <p>Verbrauch: {{ consumption }}</p>
  <p v-if="price != 0">Kosten: {{ price }}€</p>
  <p v-if="stop_reason != ''">Stop Grund: {{ stop_reason }}</p>
</template>

<script>
import jsonData from "../../files/alias.json";
import priceJson from "../../files/price.json";
export default {
  props: [
    "id",
    "start_value",
    "stop_value",
    "rfid",
    "start_time",
    "stop_time",
    "stop_reason",
  ],
  data() {
    return {
      json: jsonData,
      price: 0,
    };
  },
  methods: {
    priceFn: function (kWh) {
      let price = kWh *   priceJson.price; // price kWh
      let rounded = parseFloat(price).toFixed(2);
      rounded = rounded.replace(".", ",");
      this.price = rounded;
    },
  },
  computed: {
    consumption: function () {
      let consum = (this.stop_value - this.start_value) / 1000;
      if (consum > -1) {
        this.priceFn(consum);
        return consum + " kWh";
      } else {
        return "still Charging";
      }
    },
    rfidAlias: function () {
      let rfid = this.rfid;
      let json = this.json;
      json.forEach((element) => {
        if (element["rfid"] == rfid) {
          rfid = element["name"];
        }
      });
      return rfid;
    },
  },
};
</script>
