<template>
  <h1>Charging Sessions</h1>
  <div v-for="job in jobs.data" :key="job.transaction_pk" class="job">
    <router-link
      :to="{
        name: 'ChargingSessionsDetails',
        params: {
          id: job.transaction_pk,
          start_value: job.start_value,
          stop_value: job.stop_value,
          rfid: job.id_tag,
          start_time: formatDate(job.start_event_timestamp),
          stop_time: formatDate(job.stop_event_timestamp),
          stop_reason: job.stop_reason,
        },
      }"
    >
      <h2>{{ formatDate(job.start_event_timestamp) }} Uhr</h2>
    </router-link>
  </div>
</template>

<script>
import urlJson from "../../files/url.json";
export default {
  data() {
    return {
      jobs: [],
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('de-DE', {
        dateStyle: "long",
        timeStyle: "short",
        timeZone: 'Europe/Berlin',
        hour12: false,
      }).format(date);
    },
  },
  mounted() {
    fetch(urlJson, {
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((data) => (this.jobs = data))
      .catch((err) => console.log(err.message));
  },
};
</script>
