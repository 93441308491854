<template>
  <img class="col, pic" :src="require('./assets/BO-LOGO.png')" />
  <div id="nav">
    <router-link class="col" :to="{ name: 'Monatsabrechnung' }"
      >Abrechnung</router-link
    >
    |
    <router-link class="col" :to="{ name: 'ChargingSessions' }"
      >Alle Ladungen</router-link
    >
  </div>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
}

#nav a.router-link-exact-active {
  color: white;
  background: orchid;
}
button {
  margin: 0 10px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.pic {
  width: 10em;
  margin-left: -0.6em;
}
.job h2 {
  background: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  margin: 10px auto;
  max-width: 600px;
  cursor: pointer;
  color: #444;
}
.job h2:hover {
  background: #ddd;
}
.job a {
  text-decoration: none;
}
.jobcard {
  flex-direction: column;
  justify-content: center;
  background: #f4f4f4;
  color: #444;
  border: 1px solid #ddd;
  padding: 20px 20px;
  margin: 10px;
  flex: 1 0 8%;
}
</style>
