<template>
  <h1>{{ id }}</h1>
  <div>
    <div v-for="job in summaryData" :key="job" class="jobcard">
      <p>{{ rfidAlias(job.tag) }}</p>
      <p>Verbrauch: {{ job.value }} kWh</p>
      <p>Kosten: {{ price(job.value) }}€</p>
    </div>
  </div>
</template>

<script>
import jsonData from "../files/alias.json";
import urlJson from "../files/url.json";
import priceJson from "../files/price.json";

export default {
  props: ["id"],
  data() {
    return {
      d: [],
      monthData: [],
      json: jsonData,
      summaryData: [],
    };
  },
  methods: {
    formatDateToMonth(dateString) {
      dateString.forEach((el) => {
        const date = new Date(el.start_event_timestamp);
        let datum = new Intl.DateTimeFormat("default", {
          month: "long",
          year: "numeric",
        }).format(date);
        if (this.id == datum) {
          this.monthData.push(el);
        }
      });
      this.summary();
    },
    consumption: function (job) {
      let consum = job.stop_value - job.start_value;
      return consum;
    },
    rfidAlias: function (job) {
      let rfid = job;
      let json = this.json;
      json.forEach((element) => {
        if (element["rfid"] == rfid) {
          rfid = element["name"];
        }
      });
      return rfid;
    },
    price: function (kWh) {
      let price = kWh * priceJson.price // Price kWh
      let rounded = parseFloat(price).toFixed(2);
      rounded = rounded.replace(".", ",");
      return rounded;
    },
    summary: function () {
      let d = this.monthData;
      let rfid = [];
      let transaction = [];

      d.forEach((el) => {
        if (!rfid.includes(el.id_tag)) {
          rfid.push(el.id_tag);
        }
      });

      d.forEach((el) => {
        Object.keys(rfid).forEach((e) => {
          if (rfid[e] == el.id_tag) {
            let tag = el.id_tag;
            let value = this.consumption(el);
            if (value > 0) {
              transaction.push({ tag: tag, value: value });
            }
          }
        });
      });

      const objIds = transaction.reduce((a, { tag, value }) => {
        a[tag] = a[tag] || { tag, value: [] };
        return {
          ...a,
          ...{ [tag]: { tag, value: a[tag].value.concat(value) } },
        };
      }, {});

      const resultArray = Object.values(objIds);

      resultArray.forEach((el) => {
        let sum = 0;
        el.value.forEach((einzelsumme) => {
          sum = sum + einzelsumme;
        });
        let endsum = sum / 1000; // = kWh
        this.summaryData.push({ tag: el.tag, value: endsum });
      });
    },
  },
  mounted() {
    fetch(urlJson, {
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((data) => (this.d = data.data))
      .then((data) => this.formatDateToMonth(data))
      .catch((err) => console.log(err.message));
  },
};
</script>
